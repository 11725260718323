// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .swiper {
    width: 100%;
    height: 100%;
    padding: 50px 0;
  }
  
  .taste_section .swiper {
    padding: 0;
  }
  

  .swiper-slide {
    width: fit-content !important;
    /* height: 230px !important; */
  }
  
  .winersSwiper img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/swiper.css"],"names":[],"mappings":";EACE;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,UAAU;EACZ;;;EAGA;IACE,6BAA6B;IAC7B,8BAA8B;EAChC;;EAEA;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":["\n  .swiper {\n    width: 100%;\n    height: 100%;\n    padding: 50px 0;\n  }\n  \n  .taste_section .swiper {\n    padding: 0;\n  }\n  \n\n  .swiper-slide {\n    width: fit-content !important;\n    /* height: 230px !important; */\n  }\n  \n  .winersSwiper img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
